export interface Customer {
    ID: number
    DisplayName: string
    Units: Unit[]
    Sites: Site[]
}

export const UnknownOffering = "Unknown"
export const AccessOffering = "Access"
export const AlarmOffering = "Alarm"
export const RecordingOffering = "Recording"
export const StatisticsOffering = "Statistics"
export const TimelapseOffering = "Timelapse"
export const TrackingOffering = "Tracking"
export const VideoOffering = "Video"
export const WeatherOffering = "Weather"

export const OfferingPriority = new Map<string, number>([
    [AccessOffering, 100],
    [AlarmOffering, 200],
    [VideoOffering, 300],
    [RecordingOffering, 400],
    [WeatherOffering, 500],
    [StatisticsOffering, 600],
    [TrackingOffering, 700],
    [TimelapseOffering, 800],
])

export interface Offering {
    ID: number
    DisplayName: string
}

export interface Unit {
    ID: number
    SiteID: number
    CustomerID: number
    Offerings?: Offering[]
    DisplayName: string
    Type: UnitType
    ShortName: string
    Revision: number
    UnitConfig: UnitConfig
}

export enum Theme {
    THEME_UNSPECIFIED = "THEME_UNSPECIFIED",
    DARK = "DARK",
    LIGHT = "LIGHT",
}

export interface Branding {
    DefaultTheme: Theme
    Logos?: Logo[]
}

export interface Logo {
    Theme?: Theme
    SmallURL?: string
    LargeURL?: string
}

export enum UnitType {
    City = "City",
    Heavy = "Heavy",
    Hub = "Hub",
    Light = "Light",
    Micro = "Micro",
    Site = "Site",
}

export interface Site {
    ID: number
    CustomerID: number
    DisplayName: string
    SiteConfig: SiteConfig
    Archived: string | undefined
}

export interface SiteConfig {
    region?: string
}

export interface UnitConfig {
    Cameras: CameraConfig[]
    SnapshotCameraID?: number
}

export interface CameraConfig {
    ID: number
    RelativePanTilt?: RelativePanTilt
    RelativeZoom?: RelativeZoom
}

export interface RelativePanTilt {
    MinPanStep?: number
    MinTiltStep?: number
}

export interface RelativeZoom {
    MinZoomStep?: number
}
