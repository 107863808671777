import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Customer, Site, Unit } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import { minPageWidth } from "../config/sizing"
import { PageError } from "./PageError"
import { UnitAppBar } from "./UnitAppBar"

export interface SitePageProps {
    actor: WhoAmI
    units?: Unit[]
    site?: Site
    customer?: Customer
    customers: Customer[]
    allowed: boolean
    showArchived: boolean
    setShowArchived: (value: boolean) => void
    render: (site: Site) => React.ReactNode
}

export const SitePage = (props: SitePageProps) => {
    const { actor, units, site, customer, customers, allowed, showArchived, setShowArchived, render } = props

    const { siteID } = useParams()
    const { t } = useTranslation()

    const renderBody = () => {
        if (!units || units.length === 0) {
            return <PageError message={t("customer.no_unit")} />
        }
        if (!site) {
            return <PageError message={t("message.site_not_found", { id: siteID })} />
        }
        if (!allowed) {
            return <PageError message={t("message.forbidden_page")} />
        }
        return render(site)
    }

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", minWidth: minPageWidth, flexDirection: "column" }}>
            <UnitAppBar
                actor={actor}
                site={site}
                customer={customer}
                customers={customers}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
            />
            {renderBody()}
        </Box>
    )
}
