import { Typography } from "@mui/material"
import { useConfirm } from "material-ui-confirm"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes } from "react-router-dom"
import { Customer, Offering, OfferingPriority, Site } from "./api/Customer"
import { UpdateSiteRequest } from "./api/Site"
import { WhoAmI } from "./api/WhoAmI"
import useAuth from "./auth/AuthProvider"
import { RequireAuth } from "./auth/RequireAuth"
import { http, noSnackBar } from "./backend/request"
import { LoadingPage } from "./components/LoadingPage"
import { request } from "./config/headers"
import { getCurrentTimeAsString } from "./config/time"
import { endpointURL } from "./config/urls"
import { CustomerPermissionsPage } from "./routes/CustomerPermissionsPage"
import { Customers } from "./routes/Customers"
import { Login } from "./routes/Login"
import { NewUnitAlarm } from "./routes/NewUnitAlarm"
import { Password } from "./routes/Password"
import { Recovered } from "./routes/Recovered"
import { Recovery } from "./routes/Recovery"
import { Reset } from "./routes/Reset"
import { SiteVideo } from "./routes/SiteVideo"
import { SnapshotScroll } from "./routes/SnapshotScroll"
import { UnitAccess } from "./routes/UnitAccess"
import { UnitAlarm } from "./routes/UnitAlarm"
import { UnitStatistics } from "./routes/UnitStatistics"
import { UnitTimelapse } from "./routes/UnitTimelapse"
import { UnitTracking } from "./routes/UnitTracking"
import { UnitVideo } from "./routes/UnitVideo"
import { UnitWeather } from "./routes/UnitWeather"

const noActor = {
    User: {
        ID: 0,
        Username: "unauthenticated",
        Surname: "",
        Name: "",
        Email: "",
        CustomerID: 0,
    },
}

function App() {
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [error, setError] = React.useState<any>(null)

    const [actor, setActor] = React.useState<WhoAmI>(noActor)
    const [customers, setCustomers] = React.useState<Customer[]>([])
    const [offerings, setOfferings] = React.useState<Offering[]>([])
    const [showArchived, setShowArchived] = useState(false)

    const snackbar = useSnackbar()
    const auth = useAuth()
    const confirm = useConfirm()
    const { t } = useTranslation()

    const archiveSite = (site: Site) => {
        const archived = !!site.Archived
        confirm({
            title: t("confirm.generic"),
            description: t(archived ? "confirm.unarchive_site" : "confirm.archive_site", {
                site: site.DisplayName,
            }),
            confirmationText: t(archived ? "action.unarchive" : "action.archive"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: "secondary",
            },
        })
            .then(() => {
                const archiveRequest: UpdateSiteRequest = {
                    archived: !archived,
                }

                http(
                    archived ? `Unarchiving site (${site.ID})` : `Archiving site (${site.ID})`,
                    endpointURL(`sites/${site.ID}/archived`),
                    noSnackBar,
                    {
                        method: "PUT",
                        headers: request.headers,
                        body: JSON.stringify(archiveRequest),
                    }
                )
                    .then((_) => {
                        console.log(`Successfuly ${archived ? "unarchived" : "archived"} site (ID: ${site.ID})`)
                        // We flip the archived status here.
                        const archivedVal = archived ? undefined : getCurrentTimeAsString()
                        setCustomers((prevCustomers) =>
                            prevCustomers.map((customer) => {
                                return {
                                    ...customer,
                                    Sites: customer.Sites.map((s) =>
                                        s.ID === site.ID ? { ...s, Archived: archivedVal } : s
                                    ),
                                }
                            })
                        )
                    })
                    .catch((e) => console.log(e))
            })
            .catch(() => {})
    }

    useEffect(() => {
        if (auth.loading) {
            setIsLoaded(false)
            return
        }
        if (!auth.actor) {
            setActor(noActor)
            setIsLoaded(true)
            return
        }

        setActor(auth.actor)

        Promise.all([
            http<Customer[]>("Loading user's customers", endpointURL("customers"), snackbar, request).then(
                setCustomers
            ),
            http<Offering[]>("Loading global offerings", endpointURL("offerings"), snackbar, request).then((result) =>
                setOfferings(
                    result.sort(
                        (o1, o2) =>
                            (OfferingPriority.get(o1.DisplayName) || 1000000) -
                            (OfferingPriority.get(o2.DisplayName) || 1000000)
                    )
                )
            ),
        ])
            .then(() => {
                setIsLoaded(true)
            })
            .catch(setError)
    }, [snackbar, auth])

    if (error) {
        return <Typography>Error while loading application data.</Typography>
    }
    if (!isLoaded) {
        return <LoadingPage />
    }

    return (
        <>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/password" element={<Password />} />
                <Route path="/recovery" element={<Recovery />} />
                <Route path="/recovered" element={<Recovered />} />
                <Route path="/reset" element={<Reset />} />
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Customers
                                actor={actor}
                                customers={customers}
                                offerings={offerings}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                                archiveSite={archiveSite}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/video/units/:shortName"
                    element={
                        <RequireAuth>
                            <UnitVideo
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/video/sites/:siteID"
                    element={
                        <RequireAuth>
                            <SiteVideo
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/alarm/units/:shortName"
                    element={
                        <RequireAuth>
                            <UnitAlarm
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/newalarm/units/:shortName"
                    element={
                        <RequireAuth>
                            <NewUnitAlarm
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/access/units/:shortName"
                    element={
                        <RequireAuth>
                            <UnitAccess
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/permissions/customers/:customerID"
                    element={
                        <RequireAuth>
                            <CustomerPermissionsPage
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/statistics/units/:shortName"
                    element={
                        <RequireAuth>
                            <UnitStatistics
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/timelapse/units/:shortName"
                    element={
                        <RequireAuth>
                            <UnitTimelapse
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/timelapse/units/:shortName/cameras/:cameraID/presets/:preset"
                    element={
                        <RequireAuth>
                            <SnapshotScroll
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/tracking/units/:shortName"
                    element={
                        <RequireAuth>
                            <UnitTracking
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/weather/units/:shortName"
                    element={
                        <RequireAuth>
                            <UnitWeather
                                actor={actor}
                                customers={customers}
                                showArchived={showArchived}
                                setShowArchived={setShowArchived}
                            />
                        </RequireAuth>
                    }
                />
            </Routes>
        </>
    )
}

export default App
