import { PaletteMode } from "@mui/material"
import { ReactNode, createContext, useContext, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { Theme } from "../api/Customer"
import useAuth from "../auth/AuthProvider"

interface ConfigContextType {
    embedded: boolean
    mode: PaletteMode
}

const ConfigContext = createContext<ConfigContextType>({} as ConfigContextType)

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
    const [params] = useSearchParams()
    const auth = useAuth()
    const defaultMode = useMemo(() => auth?.actor?.Branding?.DefaultTheme || Theme.DARK, [auth])

    const embedded = useMemo(() => params.get("embedded") === "true", [params])
    const mode = useMemo(() => {
        const m = params.get("mode")
        switch (m) {
            case "dark":
            case "light":
                return m
        }
        return defaultMode === Theme.DARK ? "dark" : "light"
    }, [params, defaultMode])

    return <ConfigContext.Provider value={{ embedded, mode }}>{children}</ConfigContext.Provider>
}

const useConfig = () => useContext(ConfigContext)
export default useConfig
