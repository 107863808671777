import {
    Cloud,
    Domain,
    Home,
    Leaderboard,
    LocationOn,
    MeetingRoom,
    MoreVert,
    NavigateNext,
    NotificationsActive,
    PhotoCamera,
    PinDrop,
    Videocam,
} from "@mui/icons-material"
import {
    AppBar,
    Box,
    Breadcrumbs,
    Divider,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material"
import { Stack } from "@mui/system"
import { cloneElement, ReactElement, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link as ReactLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { Operation } from "../api/Authz"
import {
    AccessOffering,
    AlarmOffering,
    Customer,
    Site,
    StatisticsOffering,
    Theme,
    TimelapseOffering,
    TrackingOffering,
    Unit,
    VideoOffering,
    WeatherOffering,
} from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import { useUnitPermission } from "../auth/AuthorizerProvider"
import useConfig from "../config/Provider"
import { minPageWidth } from "../config/sizing"
import { retainConfig, staticURL } from "../config/urls"
import { commit, version } from "../config/version"
import { AccountMenu } from "./AccountMenu"
import { UnitIcon } from "./UnitIcon"

export interface Breadcrumb {
    title: string
    icon: ReactElement
}

export interface UnitAppBarProps {
    actor: WhoAmI
    customer?: Customer
    site?: Site
    unit?: Unit
    page?: Breadcrumb
    customers: Customer[]
    showArchived: boolean
    setShowArchived: (value: boolean) => void
}

export function UnitAppBar(props: UnitAppBarProps) {
    const { actor, customer, site, unit, page, customers, showArchived, setShowArchived } = props

    const [menuOpen, setMenuOpen] = useState(false)
    const anchor = useRef(null)

    const { t } = useTranslation()
    const theme = useTheme()
    const { embedded } = useConfig()

    const allowedAccess = useUnitPermission(Operation.UI_VIEW_ACCESS, unit)
    const allowedAlarm = useUnitPermission(Operation.UI_VIEW_ALARM, unit)
    const allowedVideo = useUnitPermission(Operation.UI_VIEW_VIDEO, unit)
    const allowedWeather = useUnitPermission(Operation.UI_VIEW_WEATHER, unit)
    const allowedStatistics = useUnitPermission(Operation.UI_VIEW_STATISTICS, unit)
    const allowedTracking = useUnitPermission(Operation.UI_VIEW_TRACKING, unit)
    const allowedTimelapse = useUnitPermission(Operation.UI_VIEW_TIMELAPSE, unit)

    const branding = useMemo(
        () => ({
            DefaultMode: actor?.Branding?.DefaultTheme || Theme.DARK,
            Logos: actor.Branding?.Logos || [],
        }),
        [actor]
    )
    const logo = useMemo(
        () => branding.Logos.find((l) => l.Theme === theme.palette.mode.toUpperCase()),
        [branding, theme]
    )
    const smallLogoURL = useMemo(
        () => logo?.SmallURL || staticURL(theme.palette.mode === "dark" ? "logo-small.svg" : "logo-small-light"),
        [theme, logo]
    )
    const largeLogoURL = useMemo(
        () => logo?.LargeURL || staticURL(theme.palette.mode === "dark" ? "logo.svg" : "logo-light"),
        [theme, logo]
    )

    const renderBuildInfo = () => (
        <Stack>
            <Typography fontSize="small" fontWeight="bold">
                EyeTowers Portal
            </Typography>
            <Divider sx={{ mb: "0.5rem" }} />
            <Typography fontSize="small">
                <span style={{ color: "lightgray" }}>Version:</span> {version || "unknown"}
            </Typography>
            <Typography fontSize="small">
                <span style={{ color: "lightgray" }}>Commit:</span> {commit || "unknown"}
            </Typography>
        </Stack>
    )

    const renderLinkIconItem = (icon: ReactElement, path: string, hash?: string) => (
        <Link
            underline="hover"
            color="inherit"
            to={retainConfig({
                pathname: path,
                hash: hash,
            })}
            component={hash ? HashLink : ReactLink}
        >
            <Stack direction="row" alignItems="center">
                {cloneElement(icon, {
                    fontSize: "small",
                    htmlColor: theme.palette.text.secondary,
                })}
            </Stack>
        </Link>
    )

    const renderLinkItem = (
        icon: ReactElement,
        title: string,
        hide: boolean,
        path: string,
        hash?: string,
        component?: ReactElement
    ) => (
        <Link
            underline="hover"
            color="inherit"
            to={retainConfig({
                pathname: path,
                hash: hash,
            })}
            component={hash ? HashLink : ReactLink}
        >
            <Stack direction="row" alignItems="center" spacing={{ xs: hide ? 0 : 0.5, md: 0.5 }}>
                {cloneElement(icon, {
                    fontSize: "small",
                    htmlColor: theme.palette.text.secondary,
                })}
                <Typography noWrap display={{ xs: hide ? "none" : "inherit", md: "inherit" }}>
                    {title}
                </Typography>
                {component}
            </Stack>
        </Link>
    )

    const renderTextItem = (icon: ReactElement, title: string) => (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            {cloneElement(icon, {
                fontSize: "small",
                htmlColor: theme.palette.text.secondary,
            })}
            <Typography noWrap>{title}</Typography>
        </Stack>
    )

    const renderBody = () => (
        <Stack direction="row" width="100%" minWidth={minPageWidth} pt={0.5} spacing={1} alignItems="center">
            <Tooltip title={renderBuildInfo()} disableInteractive>
                <Box
                    component="img"
                    sx={{
                        maxHeight: 38,
                        content: {
                            xs: `url(${smallLogoURL})`,
                            sm: `url(${largeLogoURL})`,
                        },
                    }}
                    pr={2}
                    alt="Logo"
                />
            </Tooltip>
            <Breadcrumbs
                separator={<NavigateNext />}
                aria-label="breadcrumb"
                sx={{
                    overflow: "hidden",
                    flex: 1,
                    py: 1,
                }}
            >
                {renderLinkIconItem(<Home />, "/")}
                {customer && renderLinkItem(<Domain />, customer.DisplayName, true, "/", `customer-${customer?.ID}`)}
                {site && renderLinkItem(<LocationOn />, site.DisplayName, true, "/", `site-${site?.ID}`)}
                {unit &&
                    renderLinkItem(
                        <UnitIcon unit={unit} />,
                        unit.ShortName,
                        false,
                        "/",
                        unit.ShortName,
                        <Tooltip title={t("action.navigate_services_tooltip")} disableInteractive>
                            <IconButton
                                color="primary"
                                ref={anchor}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setMenuOpen(true)
                                }}
                                sx={{ p: 0.5 }}
                            >
                                <MoreVert fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                {page && renderTextItem(page.icon, page.title)}
            </Breadcrumbs>
            <AccountMenu
                actor={actor.User}
                customers={customers}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
            />
        </Stack>
    )

    const renderMenuItem = (icon: ReactElement, title: string, path: string, allowed: boolean) => (
        <MenuItem dense component={ReactLink} to={retainConfig({ pathname: path })} disabled={!allowed}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
        </MenuItem>
    )

    const hasOffering = (offering: string) => !!unit?.Offerings?.some((o) => o.DisplayName === offering)

    if (embedded) {
        return null
    }

    return (
        <>
            <AppBar color="default">
                <Toolbar variant="dense">{renderBody()}</Toolbar>
            </AppBar>
            {unit && (
                <Menu
                    id="unit-menu"
                    keepMounted
                    anchorEl={anchor.current}
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                >
                    {renderMenuItem(
                        <MeetingRoom />,
                        t("offering.access"),
                        `/access/units/${unit.ShortName}`,
                        allowedAccess && hasOffering(AccessOffering)
                    )}
                    {renderMenuItem(
                        <NotificationsActive />,
                        t("offering.alarm"),
                        `/alarm/units/${unit.ShortName}`,
                        allowedAlarm && hasOffering(AlarmOffering)
                    )}
                    {renderMenuItem(
                        <Videocam />,
                        t("offering.video"),
                        `/video/units/${unit.ShortName}`,
                        allowedVideo && hasOffering(VideoOffering)
                    )}
                    {renderMenuItem(
                        <Cloud />,
                        t("offering.weather"),
                        `/weather/units/${unit.ShortName}`,
                        allowedWeather && hasOffering(WeatherOffering)
                    )}
                    {renderMenuItem(
                        <Leaderboard />,
                        t("offering.statistics"),
                        `/statistics/units/${unit.ShortName}`,
                        allowedStatistics && hasOffering(StatisticsOffering)
                    )}
                    {renderMenuItem(
                        <PinDrop />,
                        t("offering.tracking"),
                        `/tracking/units/${unit.ShortName}`,
                        allowedTracking && hasOffering(TrackingOffering)
                    )}
                    {renderMenuItem(
                        <PhotoCamera />,
                        t("offering.timelapse"),
                        `/timelapse/units/${unit.ShortName}`,
                        allowedTimelapse && hasOffering(TimelapseOffering)
                    )}
                </Menu>
            )}
            {/* Reserve space for the toolbar. */}
            <Stack>
                <Toolbar variant="dense">{renderBody()}</Toolbar>
            </Stack>
        </>
    )
}
